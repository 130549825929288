import "tinymce";
import 'tinymce/themes/silver/theme';
import 'tinymce/models/dom';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/autoresize';
import 'tinymce/icons/default';
import 'tinymce/skins/content/default/content';

window.addEventListener('turbo:load', () => {
  initializeTinymce();
});

function initializeTinymce() {
  // remove old editors (from previous page visit, preserved by Turbo)
  if (tinymce) tinymce.remove();

  // Initialize on all textareas
  const textareas = document.querySelectorAll("textarea[data-tinymce]");
  textareas.forEach((el) => initializeEditor(el, "textarea"));

  // Initialize on all textfields
  const textfields = document.querySelectorAll("input[data-tinymce]");
  textfields.forEach((el) => initializeEditor(el, "textfield"));
}

function initializeEditor(el, type) {
  const id = el.getAttribute('id');
  const maxChars = Number(el.getAttribute('maxlength'));
  const hasMaxChars = !!maxChars;
  const font = window.getComputedStyle(el).fontFamily;
  const focusColor = window.getComputedStyle(el).getPropertyValue('--color-focus-outline')
  const placeholderCss = '.mce-content-body[data-mce-placeholder] {position: relative;} .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {color: rgba(34, 47, 62, 0.7); content: attr(data-mce-placeholder); position: absolute; left: 1px;}'
  // We will only handle maxChars if this was enabled for this form field

  const css = `body { font-family: ${font}; font-size: 16px; line-height: 1.5; margin: 12px;}` + 'p { margin: 0 0 4px 0; }' + placeholderCss;
  const height = type === 'textarea' ? 160 : 50;
  const plugins = type === 'textarea' ? ['autoresize', 'wordcount'] : ['wordcount'];

  tinymce.init({
    selector: `#${id}`,
    // We don't need a skin, since we load our own styling.
    licence_key: 'gpl',
    skin: false,
    height: height,
    width: '100%',
    min_height: height,
    menubar: false,
    statusbar: false,
    branding: false,
    elementpath: false,
    entity_encoding: 'raw',
    toolbar: false,
    autoresize_bottom_margin: 0,
    plugins: plugins,
    content_style: css,
    smart_paste: false,
    paste_block_drop: true,
    paste_data_images: false,
    paste_as_text: true,
    text_patterns: false,
    setup: function (editor) {
      var charsCount = 0;

      // handle input event
      editor.on('input', (e) => {
        // we delay the truncate to let the content be updated
        if (hasMaxChars) asyncTruncate(editor, maxChars);
      });

      // update counter on backspace and delete (= not an input event)
      editor.on('keyup', function (event) {
        if (hasMaxChars && [8, 46].includes(event.keyCode)) {
          charsCount = updateCharsCounter(editor, maxChars);
        }
      });

      editor.on('focusin', function (e) {
        el.parentNode.querySelector('.tox-tinymce').style.outline = `2px solid ${focusColor}`;
      });

      editor.on('focusout', function (e) {
        el.parentNode.querySelector('.tox-tinymce').style.outline = '';
      });

      // text fields should not allow enter (= keycode 13) to insert newlines
      if (type === 'textfield') {
        editor.on('keydown', function (e) {
          if (e.keyCode == 13) {
            e.preventDefault();
          }
        });
      };

      editor.on('init', function (e) {
        editor.addShortcut("meta+b", "", "");
        editor.addShortcut("meta+i", "", "");
        editor.addShortcut("meta+u", "", "");
        editor.addShortcut("ctrl+b", "", "");
        editor.addShortcut("ctrl+i", "", "");
        editor.addShortcut("ctrl+u", "", "");
      });
    }
  });
}

function updateCharsCounter(editor, maxChars) {
  const charsCount = editor.plugins.wordcount.body.getCharacterCount();
  editor.targetElm.parentNode.querySelector('.remaining-chars').innerHTML = maxChars - charsCount;
  return charsCount;
}

function truncateContent(editor, maxChars) {
  var content = editor.getContent({ format: 'text' });
  if (content.length > maxChars) {

    const bookmark = editor.selection.getBookmark(2, true);
    editor.setContent(content.substring(0, maxChars));
    editor.selection.moveToBookmark(bookmark);
  }
}

async function asyncTruncate(editor, maxChars) {
  await truncateContent(editor, maxChars);

  charsCount = updateCharsCounter(editor, maxChars);
}
